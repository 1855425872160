import { Translation } from "../types/translation";

const tr: Translation = {
  language: "Türkçe",
  button: {
    changeLanguage: "Dil Değiştir",
    signUp: "Kayıt Ol",
    joinAsParticipant: "Katılımcı Olarak Bağlan",
    joinAsPresenter: "Sunucu Olarak Katıl",
    learnMoreAboutQuizModeOn: "QuizModeOn'u keşfet",
    quit: "Çıkış",
    yes: "Evet",
    no: "Hayır",
    inviteParticipants: "Katılımcıları Davet Et",
  },
  alert: {
    areYouSureToQuit: "Çıkmak istediğinize emin misiniz?",
    launchQuizModeOn: "Microsoft Teams'de QuizModeOn Uygulamasını Aç",
    enterPinToJoinEvent: "Etkinliğe Katılmak İçin Pin Kodunu Gir",
  },
};

export default tr;
