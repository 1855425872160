import Privacy from "./routes/Privacy";
import TermsOfUse from "./routes/TermsOfUse";
import Tab from "./routes/Tab";
import {
  HashRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Lobby from "./routes/Lobby";
import Player from "./routes/Player";
import Presenter from "./routes/Presenter";
import Configure from "./routes/Configure";

const AppRouter = () => {
  return (
    <Router>
      <Routes>
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/termsofuse" element={<TermsOfUse />} />
        <Route path="/tab" element={<Tab />} />
        <Route path="/lobby" element={<Lobby />} />
        <Route path="/player" element={<Player />} />
        <Route path="/presenter" element={<Presenter />} />
        <Route path="/configure" element={<Configure />} />
        <Route path="*" element={<Navigate to={"/lobby"} />}></Route>
      </Routes>
    </Router>
  );
};

export default AppRouter;
