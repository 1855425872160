import i18next from "i18next";
import en from "./translations/en";
import { Translation } from "./types/translation";

type ToVoidFunction<T> = {
  [K in keyof T]: T[K] extends string ? () => string : ToVoidFunction<T[K]>;
};

type TranslationWithFunctions = ToVoidFunction<Translation>;
export const translate = i18next.t;
export const translation = getTranslation<TranslationWithFunctions>(en);

interface Object {
  [key: string]: any; // Allow for dynamic keys and nested structures
}

function getTranslation<T>(
  object: Object,
  { parentKey = "", join = "." }: { parentKey?: string; join?: string } = {}
): T {
  const result: Object = {};

  for (const key in object) {
    const newKey = parentKey ? `${parentKey}${join}${key}` : key;

    if (typeof object[key] === "object" && object[key] !== null) {
      // If the value is an object, recursively get the path object
      result[key] = getTranslation(object[key], { parentKey: newKey, join });
    } else {
      // Otherwise, set the path string
      //i18next.t(newKey)
      result[key] = () => translate(newKey);
    }
  }

  return result as T;
}
