import { Page } from "./enums";

export type LocalStorage = {
  page: Page;
  presenterPin: string;
  language: "tr" | "en";
};

export const localStorageDefault: LocalStorage = {
  page: Page.LOBBY,
  presenterPin: "",
  language: "en",
};
