export enum Page {
  PLAYER = "PLAYER",
  PRESENTER = "PRESENTER",
  LOBBY = "LOBBY",
}

export enum IFrameMessageChannel {
  PIN = "PIN",
  PIN_REQUEST = "PIN_REQUEST",
  PRESENTER_PIN = "PRESENTER_PIN",
  PRESENTER_PIN_REQUEST = "PRESENTER_PIN_REQUEST",
  NAME = "NAME",
  NAME_REQUEST = "NAME_REQUEST",
  DELETE_LOCAL_STORAGE = "DELETE_LOCAL_STORAGE",
  QUIT = "QUIT",
  QUIT_SUCCESS = "QUIT_SUCCESS",
}
