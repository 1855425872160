export type Theme = {
  app: {
    body: {
      text: string
      backgroundColor: string
      backgroundColorOpacity: number
      backgroundImageMobile: string
      backgroundImageDesktop: string
    }

    toolbar: {
      text: string
      background: string
    }

    button: {
      text: string
      background: string
    }

    highscore: {
      text: string
      background: string
    }
  }
  games: {
    word_cloud: {
      backgroundImage: string
      colors: string[]
    }
    tombola: {
      card: {
        background: string
        border: string
      }
      selectedNumber: {
        text: string
        background: string
      }
    }
    wof: {
      marker: string
      background: string
      border: string
      text: string
    }
    taboo: {
      card: {
        header: {
          text: string
          background: string
        }
        body: {
          text: string
          background: string
        }
        border: string
      }
    },
    wordle: {
      keyboard: {
        text: string
        background: string
      }
    }
  }
  config?: {
    hideQuizmodeonFooter: boolean
    hideHighscoreNames: boolean
    hideTempHighscores: boolean
    hideFinalHighscores: boolean
  }
  logo?: string
}

export const theme1: Theme = {
  app: {
    body: {
      text: '#000000',
      backgroundColor: '#efefef',
      backgroundColorOpacity: 100,
      backgroundImageMobile:
        'https://cmo-templates.s3.amazonaws.com/quizmodeon/theme/pattern/preset-theme-1-landscape.jpg',
      backgroundImageDesktop:
        'https://cmo-templates.s3.amazonaws.com/quizmodeon/theme/pattern/preset-theme-1-landscape.jpg'
    },
    toolbar: {
      text: '#F8F9FA',
      background: '#7882F6'
    },

    button: {
      text: '#7882F6',
      background: '#F8F9FA'
    },

    highscore: {
      text: '#F8F9FA',
      background: '#745ccd'
    }
  },
  games: {
    word_cloud: {
      backgroundImage: '',
      colors: [
        '#007bff',
        '#6610f2',
        '#e83e8c',
        '#fd7e14',
        '#ffc107',
        '#28a745',
        '#20c997',
        '#17a2b8',
        '#6c757d',
        '#343a40'
      ]
    },
    wof: {
      marker: '#a56eae',
      background: '#7f59a4',
      border: '#bd9cde',
      text: '#f8f9fa'
    },
    tombola: {
      card: {
        background: '#a56eae',
        border: '#7f59a4'
      },
      selectedNumber: {
        text: '#bd9cde',
        background: '#f8f9fa'
      }
    },
    taboo: {
      card: {
        header: {
          text: '#63559b',
          background: '#f8f9fa'
        },
        body: {
          text: '#f3f0ff',
          background: '#63559b'
        },
        border: '#51428f'
      }
    },
    wordle: {
      keyboard: {
        text: '#f8f9fa',
        background: '#7f59a4'
      }
    }
  }
}
