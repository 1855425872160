import { initReactI18next } from "react-i18next";
import en from "../config/translations/en";
import tr from "../config/translations/tr";
import AppLayout from "./layouts/AppLayout";
import AppProvider from "./Provider";
import AppRouter from "./Router";
import i18next from "i18next";
import { useLocalStorage } from "usehooks-ts";
import { LocalStorage, localStorageDefault } from "../types/LocalStorage";

export default function App() {
  const [localStorage] = useLocalStorage<LocalStorage>(
    process.env.REACT_APP_LOCAL_STORAGE_KEY!,
    localStorageDefault
  );

  const resources = {
    en: { translation: en },
    tr: { translation: tr },
  };

  i18next
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources,
      lng: localStorage.language, // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
      // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
      // if you're using a language detector, do not define the lng option
      fallbackLng: "en",
      interpolation: {
        escapeValue: false, // react already safes from xss
      },
    });

  return (
    <AppProvider>
      <AppLayout>
        <AppRouter />
      </AppLayout>
    </AppProvider>
  );
}
