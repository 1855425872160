import TopButton from "../../components/TopButton";
import useIFrameListener from "../../hooks/useIFrameListener";
import { Page } from "../../types/enums";

const Player = () => {
  useIFrameListener();

  return (
    <>
      <TopButton page={Page.PLAYER} />
      <iframe
        title="Zoom SDK"
        className="w-full h-[93vh]"
        src={`${process.env.REACT_APP_PUBLIC_URL as string}/?inapp=zoom&type=u`}
      />
    </>
  );
};

export default Player;
