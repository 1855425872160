import { Navigate, useNavigate } from "react-router-dom";
import LanguageButton from "../../components/LanguageButton";
import { Button } from "../../components/ui/button";
import { Separator } from "../../components/ui/separator";
import { translation } from "../../config/languages";
import { useLocalStorage } from "usehooks-ts";
import { localStorageDefault } from "../../types/LocalStorage";
import { Page } from "../../types/enums";

const Lobby = () => {
  const navigate = useNavigate();
  const [localStorage, setLocalStorage] = useLocalStorage(
    process.env.REACT_APP_LOCAL_STORAGE_KEY!,
    localStorageDefault
  );

  if (localStorage.page === Page.PLAYER) {
    return <Navigate to="/player" />; 
  } else if (localStorage.page === Page.PRESENTER) {
    return <Navigate to="/presenter" />;
  }

  return (
    <div className="w-screen h-screen flex flex-col items-center justify-center px-6">
      <div className="flex w-full bg-white shadow-md items-center fixed top-0 h-[8vh]">
        <div className="flex items-center justify-center">
          <img
            className="h-[6vh] ml-2"
            alt="logo"
            src="https://cmo-templates.s3.amazonaws.com/quizmodeon/brand/logo.png"
          />

          <LanguageButton />
        </div>
        <a
          href="https://portal.quizmodeon.com/#/register"
          target="_blank"
          rel="noreferrer"
          className="ml-auto mr-2"
        >
          <Button className="bg-theme-button-text text-theme-button-bg hover:bg-theme-button-bg hover:text-theme-button-text">
            {translation.button.signUp()}
          </Button>
        </a>
      </div>

      <Button
        className="bg-theme-button-bg text-theme-button-text w-full p-6 text-xl max-w-96"
        onClick={() => {
          navigate("/player");
          setLocalStorage({ ...localStorage, page: Page.PLAYER });
        }}
      >
        {translation.button.joinAsParticipant()}
      </Button>

      <Separator className="my-4 bg-theme-button-bg max-w-96" />

      <Button
        className="bg-theme-button-bg text-theme-button-text w-full p-6 text-lg max-w-96"
        onClick={() => {
          navigate("/presenter");
          setLocalStorage({ ...localStorage, page: Page.PRESENTER });
        }}
      >
        {translation.button.joinAsPresenter()}
      </Button>

      <a
        href="https://quizmodeon.com/?utm_source=zoomapp"
        className="mt-10 underline text-theme-body-text text-center hover:cursor-pointer"
        target="_blank"
        rel="noreferrer"
      >
        {translation.button.learnMoreAboutQuizModeOn()}
      </a>
    </div>
  );
};

export default Lobby;
