import { CSSProperties, ReactNode } from "react";
import { theme1 } from "../../types/Theme";

type AppLayoutProps = {
  children: ReactNode;
};

const AppLayout = ({ children }: AppLayoutProps) => {
  const portraitBackground = `https://cmo-templates.s3.amazonaws.com/quizmodeon/theme/pattern/preset-theme-1-portrait.jpg`;
  const landscapeBackground = `https://cmo-templates.s3.amazonaws.com/quizmodeon/theme/pattern/preset-theme-1-landscape.jpg`;

  const colors = {
    "--theme-body-text-color": theme1.app.body.text,
    "--theme-toolbar-text-color": theme1.app.toolbar.text,
    "--theme-toolbar-bg-color": theme1.app.toolbar.background,
    "--theme-button-text-color": theme1.app.button.text,
    "--theme-button-bg-color": theme1.app.button.background,
  };

  return (
    <>
      <div
        style={
          {
            "--bg-portrait": `url(${portraitBackground})`,
            "--bg-landscape": `url(${landscapeBackground})`,
          } as CSSProperties
        }
        className={`portrait:bg-[image:var(--bg-portrait)] landscape:bg-[image:var(--bg-landscape)] bg-no-repeat bg-cover h-screen w-screen fixed -z-10`}
      ></div>
      <div style={{ ...colors as CSSProperties }} className="absolute h-screen w-screen">
        {children}
      </div>
    </>
  );
};

export default AppLayout;
