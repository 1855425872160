import { Translation } from "../types/translation";

const en: Translation = {
  language: "English",
  button: {
    changeLanguage: "Change Language",
    signUp: "Sign Up",
    joinAsParticipant: "Join as a Participant",
    joinAsPresenter: "Join as a Host",
    learnMoreAboutQuizModeOn: "Learn more about QuizModeOn",
    quit: "Quit",
    yes: "Yes",
    no: "No",
    inviteParticipants: "Invite Participants",
  },
  alert: {
    areYouSureToQuit: "Are you sure you want to quit?",
    launchQuizModeOn: "Launch QuizModeOn App In Microsoft Teams",
    enterPinToJoinEvent: "Enter Pin To Join Event",
  },
};

export default en;
