import { Spinner } from "@fluentui/react-components";
import { TeamsFxContext } from "./contexts/Context";
import { useTeamsUserCredential } from "@microsoft/teamsfx-react";
import config from "../lib/config";
import { ReactNode } from "react";

type AppProviderProps = {
  children: ReactNode;
};

const AppProvider = ({ children }: AppProviderProps) => {
  const { loading, theme, themeString, teamsUserCredential } =
    useTeamsUserCredential({
      initiateLoginEndpoint: config.initiateLoginEndpoint!,
      clientId: config.clientId!,
    });
  
  if (loading) return <Spinner style={{ margin: 100 }} />;

  return (
    <TeamsFxContext.Provider
      value={{ theme, themeString, teamsUserCredential }}
    >
      {children}
    </TeamsFxContext.Provider>
  );
};

export default AppProvider;
