import { useNavigate } from "react-router-dom";
import { IFrameMessageChannel, Page } from "../types/enums";
import sendMessageToIframe from "../utils/sendMessageToIframe";
import { Button } from "./ui/button";
import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogClose,
} from "./ui/dialog";
import * as microsoftTeams from "@microsoft/teams-js";
import { translation } from "../config/languages";
import { useAppStore } from "../stores/app";
import { useLocalStorage } from "usehooks-ts";
import { localStorageDefault } from "../types/LocalStorage";

const TopButton = ({ page }: { page: Page }) => {
  const navigate = useNavigate();
  const { pinFromPresenter } = useAppStore();
  const [localStorage, setLocalStorage] = useLocalStorage(
    process.env.REACT_APP_LOCAL_STORAGE_KEY!,
    localStorageDefault
  );

  return (
    <div className="flex w-full bg-white shadow-md items-center h-[7vh]">
      <Dialog>
        <DialogTrigger asChild>
          <Button className="bg-transparent text-theme-button-text top-0 rounded-none text-xl h-[8vh] hover:bg-transparent shadow-none">
            {translation.button.quit()}
          </Button>
        </DialogTrigger>

        <DialogContent>
          <DialogHeader>
            <DialogTitle>{translation.alert.areYouSureToQuit()}</DialogTitle>
          </DialogHeader>

          <DialogFooter>
            <DialogClose asChild className="my-2">
              <Button>{translation.button.no()}</Button>
            </DialogClose>
            <DialogClose asChild className="my-2">
              <Button
                onClick={() => {
                  if (page === Page.PLAYER) {
                    sendMessageToIframe(IFrameMessageChannel.QUIT);
                  } else {
                    navigate("/lobby");
                    setLocalStorage({
                      ...localStorage,
                      page: Page.LOBBY,
                      presenterPin: "",
                    });
                  }
                }}
              >
                {translation.button.yes()}
              </Button>
            </DialogClose>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      {page === Page.PRESENTER ? (
        <Button
          className="bg-theme-button-text text-theme-button-bg hover:bg-theme-button-bg hover:text-theme-button-text ml-auto mr-2"
          onClick={() =>
            microsoftTeams.sharing.shareWebContent({
              content: [
                {
                  type: "URL",
                  message:
                    translation.alert.enterPinToJoinEvent() +
                    " : " +
                    "<br/>" +
                    "<b><h1>" +
                    pinFromPresenter +
                    "</h1></b><br/>" +
                    translation.alert.launchQuizModeOn() +
                    " : ",
                  url: process.env.REACT_APP_APP_DEEP_LINK as string,
                  preview: true,
                },
              ],
            })
          }
        >
          {translation.button.inviteParticipants()}
        </Button>
      ) : null}
    </div>
  );
};

export default TopButton;
