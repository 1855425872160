import { IFrameMessageChannel } from "../types/enums";

const sendMessageToIframe = (channel: IFrameMessageChannel, message?: string) => {
  const iframe = document.querySelector("iframe");
  iframe?.contentWindow?.postMessage(
    {
      channel: channel,
      message: message || "",
    },
    "*"
  );
};

export default sendMessageToIframe;
