import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../components/ui/dropdown-menu";
import { TriangleDownIcon } from "@radix-ui/react-icons";
import { translation } from "../config/languages";
import { useLocalStorage } from "usehooks-ts";
import { LocalStorage, localStorageDefault } from "../types/LocalStorage";

const LanguageButton = () => {
  const [localStorage, setLocalStorage] = useLocalStorage<LocalStorage>(
    process.env.REACT_APP_LOCAL_STORAGE_KEY!,
    localStorageDefault
  );

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <div className="flex items-center border-l-2 h-full pl-2 hover:cursor-pointer">
          {translation.language()}

          <TriangleDownIcon />
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56">
        <DropdownMenuLabel>
          {translation.button.changeLanguage()}
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuGroup>
          <DropdownMenuItem onClick={() => setLocalStorage({ ...localStorage, language: "en" })}>
            English
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => setLocalStorage({ ...localStorage, language: "tr" })}>
            Türkçe
          </DropdownMenuItem>
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default LanguageButton;
