import { useCallback, useContext, useEffect } from "react";
import sendMessageToIframe from "../utils/sendMessageToIframe";
import { IFrameMessageChannel, Page } from "../types/enums";
import { useNavigate } from "react-router-dom";
import { useData } from "@microsoft/teamsfx-react";
import { TeamsFxContext } from "../app/contexts/Context";
import { useAppStore } from "../stores/app";
import { useLocalStorage } from "usehooks-ts";
import { localStorageDefault } from "../types/LocalStorage";

const useIFrameListener = () => {
  const navigate = useNavigate();
  const { teamsUserCredential } = useContext(TeamsFxContext);
  const { loading, data, error } = useData(async () => {
    if (teamsUserCredential) {
      const userInfo = await teamsUserCredential.getUserInfo();
      return userInfo;
    }
  });
  const { setPinFromPresenter } = useAppStore();
  const [localStorage, setLocalStorage] = useLocalStorage(
    process.env.REACT_APP_LOCAL_STORAGE_KEY!,
    localStorageDefault
  );

  const userName = loading || error || !data ? "" : data.displayName;

  const handleMessage = useCallback(
    (
      event: MessageEvent<{
        channel: IFrameMessageChannel;
        message: string;
      }>
    ) => {
      if (event.data.channel === IFrameMessageChannel.NAME_REQUEST) {
        sendMessageToIframe(IFrameMessageChannel.NAME, userName);
      } else if (event.data.channel === IFrameMessageChannel.QUIT_SUCCESS) {
        navigate("/lobby");
        setLocalStorage({ ...localStorage, page: Page.LOBBY });
      } else if (event.data.channel === IFrameMessageChannel.PIN) {
        setPinFromPresenter(event.data.message);
      } else if (event.data.channel === IFrameMessageChannel.PRESENTER_PIN_REQUEST) {
        sendMessageToIframe(IFrameMessageChannel.PRESENTER_PIN, localStorage.presenterPin);
      } else if (event.data.channel === IFrameMessageChannel.PRESENTER_PIN) {
        console.log("presenter pin", event.data.message);
        setLocalStorage({ ...localStorage, presenterPin: event.data.message });
      }
    },
    [localStorage, navigate, setLocalStorage, setPinFromPresenter, userName]
  );

  useEffect(() => {
    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [handleMessage]);
};

export default useIFrameListener;
