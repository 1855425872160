import { create } from "zustand";

type AppStore = {
  pinFromPresenter: string;
  setPinFromPresenter: (pinFromPresenter: string) => void;
};

export const useAppStore = create<AppStore>((set) => ({
  pinFromPresenter: "",
  setPinFromPresenter: (pinFromPresenter: string) => set({ pinFromPresenter }),
}));
