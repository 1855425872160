import { useEffect } from "react";
import * as microsoftTeams from "@microsoft/teams-js";

const Configure = () => {
  useEffect(() => {
    microsoftTeams.app.initialize().then(() => {
      microsoftTeams.pages.config.setValidityState(true);
      microsoftTeams.pages.config.registerOnSaveHandler((saveEvent) => {
        const configPromise = microsoftTeams.pages.config.setConfig({
          websiteUrl:
            "https://cmo-templates.s3.amazonaws.com/quizmodeon/brand/logo.png",
          contentUrl: process.env.REACT_APP_CONTENT_URL as string,
          entityId: "index",
          suggestedDisplayName: "QuizModeOn",
        });
        configPromise
          .then((result) => {
            saveEvent.notifySuccess();
          })
          .catch((error) => {
            saveEvent.notifyFailure("failure message");
          });
      });
    });
  }, []);

  return (
    <div className="h-screen w-screen flex items-center justify-center">
      <img
        src="https://cmo-templates.s3.amazonaws.com/quizmodeon/brand/logo.png"
        alt="logo"
      />
    </div>
  );
};

export default Configure;
